<template>
  <div>
    <div class="topbar">
      <div
        class="container flex-column flex-md-row align-items-start align-items-md-end gap-2"
      >
        <div class="brand">
          <router-link to="/">
            <img src="/svgs/logo_6.svg" />
          </router-link>
        </div>

        <h6>LIVE, LEARN & <span class="text-primary">SPEAK!</span></h6>
      </div>
    </div>
    <PageHeader fileName="header-18" class="partnership-page-header">
      <div class="text-left pb-5">
        <h1 class="mb-5 d-none d-md-block">
          Welcome to our <span class="text-secondary"> partnership area </span>
        </h1>
        <h2 class="mb-4 d-none d-sm-block d-md-none">
          Welcome to our <span class="text-secondary"> partnership area </span>
        </h2>
        <h3 class="mb-4 d-block d-sm-none">
          Welcome to our <span class="text-secondary"> partnership area </span>
        </h3>

        <h4>
          Download all our materials in high-resolution: <br />
          pictures, video, logos, brochures and more.
        </h4>
      </div>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <h1 class="mb-4">
              About <span class="text-secondary"> our school </span>
            </h1>
          </div>
          <div class="col-lg-6 offset-lg-1 mt-4 mt-lg-0">
            <h6 class="fw-400 mb-4 lh-3">
              Our first campus in Spain was established in Madrid back in 2017.
              Our primary objective has always been to help our students learn a
              new language while creating unforgettable experiences and
              memories.
            </h6>
            <p>
              In 2022, we decided it was time to expand: a new campus with super
              infrastructure was inaugurated in Barcelona to keep this goal
              growing. Our commitment to our students remains the same - to
              provide excellent teaching and ensure they cherish their time in
              Spain forever. We have a team of experienced, native-speaking
              teachers and multicultural support staff who are always available
              for our students to answer questions and contribute to their
              well-being.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-dark" id="buttons">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <h1>Media <span class="text-secondary">Library</span></h1>
          </div>
          <div class="col-lg-6 offset-lg-1 mt-4 mt-lg-0">
            <div class="d-grid grid-md-2-cols gap-4">
              <CardButton
                theme="primary"
                :title="item.title"
                :to="`#${item.id}`"
                v-for="(item, index) of sections"
                :key="index"
              >
                <small class="m-0">
                  {{ item.button }}
                </small>
              </CardButton>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="py-6 bg-dark"
      :id="section.id"
      v-for="(section, index) of sections"
      :key="index"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="d-flex flex-column h-100">
              <h3 class="text-secondary mb-4">{{ section.title }}</h3>

              <div class="mb-5" v-html="section.description"></div>

              <a href="#buttons" class="text-secondary mt-auto">
                <i class="fa fa-arrow-up mr-3"></i>
                Go back to top
              </a>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1 mt-4 mt-lg-0">
            <template v-if="section.items">
              <ul class="connected-list h-100 list-until-end list-secondary">
                <li
                  class="d-grid gap-3"
                  :class="`grid-md-${section.items_cols}-cols`"
                >
                  <div
                    v-for="(subitem, subix) of section.items"
                    :key="subix"
                    @click="goToLink(subitem.link)"
                    :class="{ pointer: subitem.link }"
                  >
                    <ImageContainer
                      :fileName="subitem.filename"
                      :size="subitem.size"
                      class="img-container-rounded-sm bg-medium-shade"
                    />
                    <small v-if="subitem.title" class="mt-3 d-block">
                      {{ subitem.title }}
                    </small>
                  </div>
                </li>
              </ul>
            </template>

            <template v-if="section.images">
              <ul
                class="connected-list list-until-end list-secondary mb-5"
                v-for="(imageGroup, groupIx) of section.images"
                :key="groupIx"
              >
                <li>
                  <h4 class="mb-4">{{ imageGroup.title }}</h4>
                  <div
                    class="d-grid gap-3"
                    :class="`grid-md-${section.items_cols}-cols`"
                  >
                    <ImageContainer
                      v-for="(subitem, subix) in imageGroup.items"
                      :key="subix"
                      :fileName="subitem.img"
                      class="img-container-rounded-sm pointer"
                      @click="openImageURL(subitem.url)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </section>

    <ImageContainer
      class="img-section-header contact-section"
      fileName="footer-1"
      :size="600"
    >
      <div class="container">
        <div class="row justify-content-center mb-7">
          <div class="col-md-10 col-lg-8 text-center">
            <h2 class="text-white mb-0">We want to hear</h2>
            <h2 class="text-secondary mb-5">from you!</h2>
          </div>

          <div class="col-md-7 col-lg-5 text-center">
            <p class="mb-6">
              If you want to collaborate with us, do not hesitate to get in
              touch with us. We are looking forward to hearing your ideas!
            </p>

            <a
              href="mailto:partnership@nlcollege.es"
              class="btn btn-primary btn-block"
              >CONTACT US</a
            >
          </div>
        </div>

        <div class="row align-items-end">
          <div class="col-lg-4 text-center mb-4 text-lg-left mb-lg-0">
            <router-link class="brand" to="/">
              <img src="/svgs/logo.svg" alt="" />
            </router-link>
          </div>
          <div class="col-lg-4 text-center">
            <div class="social-buttons mb-4">
              <a
                href="https://www.instagram.com/nlcollege.spain"
                target="_blank"
                class="btn btn-rounded btn-white"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/c/NLCollegeSpain"
                target="_blank"
                class="btn btn-rounded btn-white"
              >
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain"
                target="_blank"
                class="btn btn-rounded btn-white"
              >
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://www.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-rounded btn-white"
              >
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-rounded btn-white"
              >
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </div>

            <div class="d-flex gap-3 justify-content-center">
              <a class="text-white a-decorated" href="http://nlcollege.es"
                >nlcollege.es</a
              >
              <a class="text-white a-decorated" href="mailto:info@nlcollege.es"
                >info@nlcollege.es</a
              >
            </div>
          </div>
          <div class="col-lg-4 text-center mt-4 text-lg-right mt-lg-0">
            <p class="m-0">© 2024</p>
          </div>
        </div>
      </div>
    </ImageContainer>

    <ImageGallery
      :activeIndex="selectedImageIndex"
      :images="imagesArray"
      :title="galleryTitle"
      :folder="galleryFolder"
      @close="closeGallery"
      @next="nextImage"
      @previous="prevImage"
      v-if="imagesArray.length"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import CardButton from "@/components/CardButton.vue";
import ImageGallery from "@/components/ImageGallery.vue";

export default {
  components: {
    PageHeader,
    ImageContainer,
    CardButton,
    ImageGallery,
  },
  data: () => ({
    sections: [
      {
        id: "brochure",
        title: "NL College brochure",
        button: `Download the digital version of our brochure. This file
          contains information about our campuses, mission, courses,
          levels, student life, and extra activities`,
        description: `
          <p>Download the digital version of our brochure.</p>
          <p class="mb-5">
            With our digital catalog, you will have all the information
            necessary to get to know us a little better. In it, you will
            find detailed information about our campuses, mission, courses,
            levels, student life, Visa, and extra activities.
          </p>
        `,
        items_cols: 2,
        items: [
          {
            filename: "brochure_cn",
            title: "Chinese version",
            size: 300,
            link: "https://drive.google.com/open?id=1ofHn9NJ8ckPsN9gYTFxrH7d9J8RzHpeE&usp=drive_copy",
          },
          {
            filename: "brochure_en",
            title: "English version",
            size: 300,
            link: "https://drive.google.com/file/d/1Tg7h4n-TzYC34DrwHPigWHFRSjR7Lb1f/view?usp=drive_link",
          },
          {
            filename: "brochure_pr",
            title: "Portuguese version",
            size: 300,
            link: "https://drive.google.com/open?id=1L__6KfQ0GgbVFtQtnnf4Kqzskk7FglUV&usp=drive_copy",
          },
          {
            filename: "brochure_ru",
            title: "Russian version",
            size: 300,
            link: "https://drive.google.com/open?id=161xpJOgLgl3XotyM0KcvaDxm4cUleQWK&usp=drive_copy",
          },
        ],
      },
      {
        id: "pricelist",
        title: "Pricelist",
        button: `Download the NL College price list for 2024`,
        description: `
          <p class="mb-5">Download the NL College price list for 2024</p>
        `,
        items_cols: 2,
        items: [
          {
            filename: "pricelist_2025",
            size: 300,
            link: "https://drive.google.com/file/d/1b8gUQPH8mzDxgvo4wk3QqR_Mg0NoDK7u/view?usp=drive_link",
          },
        ],
      },
      {
        id: "calendar",
        title: "Calendar",
        button: `Download our 2024 calendar for Barcelona and Madrid`,
        description: `
          <p class="mb-5">
            Download our 2024 calendar for Barcelona and Madrid. In this calendar,
            you will find marked the start of all our courses, local and national
            holidays, vacations, and dates of official exams.
          </p>
        `,
        items_cols: 2,
        items: [
          {
            filename: "calendar_barcelona_2025",
            title: "Campus Barcelona",
            size: 300,
            link: "https://drive.google.com/file/d/1K0-uvo_-lr8aQMtkgtTiBjjcvn71lKdX/view",
          },
          {
            filename: "calendar_madrid_2025",
            title: "Campus Madrid",
            size: 300,
            link: "https://drive.google.com/file/d/1S6s9xrONfWHxxk0uznMDRh3OKQS8HfHF/view",
          },
        ],
      },
      {
        id: "video_gallery",
        title: "Video Gallery",
        button: `Download high quality videos of our Campuses, students, activities and more`,
        description: `
          <p class="mb-5">
            Select the video you prefer or download the complete gallery <a href="https://drive.google.com/file/d/1Aq7CthxyO8bb9otF9SN2WoN5jg9WTb9_/view?usp=share_link" target="_blank" class="text-secondary">here</a>
          </p>
        `,
        items_cols: 2,
        items: [
          {
            filename: "nlcollege_life",
            size: 150,
            title: "NL College life",
            link: "https://drive.google.com/file/d/1lPPw__DBc51CkvUEGDGayHHkrLmHvh57/view?usp=share_link",
          },
          {
            filename: "english_teacher",
            size: 150,
            title: "Our English teacher shows you around",
            link: "https://drive.google.com/file/d/1MSzb-XoXEyu9tVEFnjCA_-QVrhzyhWme/view?usp=share_link",
          },
          {
            filename: "spanish_teacher",
            size: 150,
            title: "Our Spanish teacher shows you around",
            link: "https://drive.google.com/file/d/1Elh8x7YbtYgd-0iwCwrGGHbiGHJ2bmUU/view?usp=share_link",
          },
        ],
      },
      {
        id: "image_gallery",
        title: "Image Gallery",
        button: `Download high quality images of our Campuses, students, activities and more`,
        description: `
          <p class="mb-5">
            Download the high quality Barcelona's gallery <a href="https://drive.google.com/file/d/1qQ9EDWE1E8s7MOqr881mL6pX9oUVGdhN/view?usp=sharing" target="_blank" class="text-secondary">here</a><br>
            Download the high quality Madrid's gallery <a href="https://drive.google.com/file/d/1Lr5PoNgkujcJHrr-swibubRCsAN5RLSs/view?usp=sharing" target="_blank" class="text-secondary">here</a>
          </p>
        `,
        items_cols: 3,
        images: [
          {
            title: "Campus Barcelona",
            folder: "/files/campus_barcelona/",
            items: [
              {
                img: "brc_01",
                url: "https://drive.google.com/file/d/1IJc1BHjpMv4-clcBfePXJK7uGkRn8i_3/view?usp=drive_link",
              },
              {
                img: "brc_02",
                url: "https://drive.google.com/file/d/1FhOvBY9oYJcHCe5fMwwaKaZzlgjazlOC/view?usp=drive_link",
              },
              {
                img: "brc_03",
                url: "https://drive.google.com/file/d/1pFwQ1Nn5N7gfCFEg-sjF6oITv4xM9Ftf/view?usp=drive_link",
              },
              {
                img: "brc_04",
                url: "https://drive.google.com/file/d/1sQfEzMAZk2_54qtqyF-T7HoRhNM3ro4C/view?usp=drive_link",
              },
              {
                img: "brc_05",
                url: "https://drive.google.com/file/d/1lho95HwAoFm1G6e7P1sPOXWUXxSHhUrR/view?usp=drive_link",
              },
              {
                img: "brc_06",
                url: "https://drive.google.com/file/d/1ISvbSa-zZafAhWoAgcSEKTS5qrNZiFb_/view?usp=drive_link",
              },
              {
                img: "brc_07",
                url: "https://drive.google.com/file/d/1o-4RQTfYk833aA55mBxGp9I4rDkKm-uy/view?usp=drive_link",
              },
              {
                img: "brc_08",
                url: "https://drive.google.com/file/d/1yMnvbayqPKtDWBFFvNPFkXzqP7-_WmNn/view?usp=drive_link",
              },
              {
                img: "brc_09",
                url: "https://drive.google.com/file/d/1i3cKq9BNhhLXPHUDKiK_KrYc5ueb7zYB/view?usp=drive_link",
              },
              {
                img: "brc_10",
                url: "https://drive.google.com/file/d/12nXtxP57Z7L_5b-4qbsaN2tOInb83Lom/view?usp=drive_link",
              },
              {
                img: "brc_11",
                url: "https://drive.google.com/file/d/1Jlm69-OZbZaK--zD32-tW2Sn02tgdLvo/view?usp=drive_link",
              },
              {
                img: "brc_12",
                url: "https://drive.google.com/file/d/1cazRJrptxeJonuOcLUIuzzKfZDAo9UDs/view?usp=drive_link",
              },
              {
                img: "brc_13",
                url: "https://drive.google.com/file/d/1ORMYl6ALmtoNiGUvBQrm-tu8by1P04Nb/view?usp=drive_link",
              },
              {
                img: "brc_14",
                url: "https://drive.google.com/file/d/1l2fVi7QYvJviijvHbq-da83Pyft2Qwhl/view?usp=drive_link",
              },
              {
                img: "brc_15",
                url: "https://drive.google.com/file/d/1BCUtvFgzK4LBifrdbSKC5MyCohX8PgU9/view?usp=drive_link",
              },
              {
                img: "brc_16",
                url: "https://drive.google.com/file/d/1yrIooLo0jVua2UGt0Mc-GXwieJDvzqto/view?usp=drive_link",
              },
              {
                img: "brc_17",
                url: "https://drive.google.com/file/d/1J1Imjmy5WxSXcAA8ESQz23s9lHucUPvH/view?usp=drive_link",
              },
              {
                img: "brc_18",
                url: "https://drive.google.com/file/d/1ZzZMPHlKDLEIdPfFua1A_VV5a5nG4_uy/view?usp=drive_link",
              },
              {
                img: "brc_19",
                url: "https://drive.google.com/file/d/1ymRNEkXukd6dg_w7c7a1i1V7UFp_vFxC/view?usp=drive_link",
              },
              {
                img: "brc_20",
                url: "https://drive.google.com/file/d/1m13KlpMn4mBr5aJGV5d-Deh5HOWsYPUR/view?usp=drive_link",
              },
              {
                img: "brc_27",
                url: "https://drive.google.com/file/d/1l_j1Y_c2n80U6yCtT-Q_2FJc3IC2JpH0/view?usp=drive_link",
              },
              {
                img: "brc_28",
                url: "https://drive.google.com/file/d/1glmTI-nE0LukXAu-QIgAPdV-w4TNnqBj/view?usp=drive_link",
              },
              {
                img: "brc_29",
                url: "https://drive.google.com/file/d/19BszQRVTMWYBYvJuoxi2NUu20WR32xf0/view?usp=drive_link",
              },
              {
                img: "brc_30",
                url: "https://drive.google.com/file/d/1nTpWi6Om9vMCl66WHNZycW7QGugz9fX-/view?usp=drive_link",
              },
              {
                img: "brc_31",
                url: "https://drive.google.com/file/d/10Ib6vpO-c8Mq0Jot3nf5A2Oq89_2K85K/view?usp=drive_link",
              },
              {
                img: "brc_32",
                url: "https://drive.google.com/file/d/17Jk4xtwhmPELDpQghIn9mdwkNTuG_Anl/view?usp=drive_link",
              },
              {
                img: "brc_33",
                url: "https://drive.google.com/file/d/1tDBA1RhLWkg0ftC5k2ho6PK3opTwT_Df/view?usp=drive_link",
              },
              {
                img: "brc_34",
                url: "https://drive.google.com/file/d/12_GFNsCJP7LIMLz_pg_wokqFQHKVyoTO/view?usp=drive_link",
              },
              {
                img: "brc_35",
                url: "https://drive.google.com/file/d/1mXzu3R-lASCxbkLI8gIaU6CJgH8qdlZe/view?usp=drive_link",
              },
              {
                img: "brc_36",
                url: "https://drive.google.com/file/d/1UUPrjvk52h9nN51G2IuHpl_7kqOnSk8x/view?usp=drive_link",
              },
              {
                img: "brc_37",
                url: "https://drive.google.com/file/d/1MIcuz_Ejuj2aW49SlauspDKwE_ydCld5/view?usp=drive_link",
              },
              {
                img: "brc_38",
                url: "https://drive.google.com/file/d/1IdO8hyu_SoM8wgJw0Co5ZKFoytith5nj/view?usp=drive_link",
              },
              {
                img: "brc_39",
                url: "https://drive.google.com/file/d/1_tUf2LLNRcVOzdeZpF-kVXtvzrArg_ZC/view?usp=drive_link",
              },
              {
                img: "brc_40",
                url: "https://drive.google.com/file/d/1qqNe1uRMKSXh8wPqFGfLGe8r7XL9g9c2/view?usp=drive_link",
              },
              {
                img: "brc_41",
                url: "https://drive.google.com/file/d/1-RTRP0nxuXOGzcuXuuOzZxAqDla_4dR4/view?usp=drive_link",
              },
              {
                img: "brc_42",
                url: "https://drive.google.com/file/d/1Pybg4Ps95eHGo0OU0UF98y0Txnk4y8Qj/view?usp=drive_link",
              },
              {
                img: "brc_43",
                url: "https://drive.google.com/file/d/1ZJKDC9jWkWRV0KZejQ9fG_r1hpOVgz-A/view?usp=drive_link",
              },
              {
                img: "brc_44",
                url: "https://drive.google.com/file/d/115rYJGW-7qnS_OG-lz3uZ2q5GMZQbgZx/view?usp=drive_link",
              },
              {
                img: "brc_45",
                url: "https://drive.google.com/file/d/1T-38smBIgTzXx8Bj1KfIYXj7LuhVbZow/view?usp=drive_link",
              },
              {
                img: "brc_46",
                url: "https://drive.google.com/file/d/1nWC4mwIxsCllxL93gMFDuf94M1TZVIA6/view?usp=drive_link",
              },
              {
                img: "brc_47",
                url: "https://drive.google.com/file/d/1sVRYRWWdImaKxMs_e_C4aUiw_BsPANgi/view?usp=drive_link",
              },
              {
                img: "brc_48",
                url: "https://drive.google.com/file/d/1Zwwr5AXAMgECItFfedHBy97MGU6shZZm/view?usp=drive_link",
              },
              {
                img: "brc_49",
                url: "https://drive.google.com/file/d/1kWiEn-QHov1CKwfthvlmK3xN1Wyc_OXE/view?usp=drive_link",
              },
              {
                img: "brc_50",
                url: "https://drive.google.com/file/d/1cEa528xG1yIV_xp2Pu9dVqAS7VqBDXwi/view?usp=drive_link",
              },
              {
                img: "brc_51",
                url: "https://drive.google.com/file/d/1nma0Vcc2FmQ8g_w93aCwXK0n2wctqoz0/view?usp=drive_link",
              },
            ],
          },
          {
            title: "Campus Madrid",
            folder: "/files/campus_madrid/",
            items: [
              {
                img: "mdr_01",
                url: "https://drive.google.com/file/d/1XNL8smAWA1rQNlppd75uHutv9IRmqyKQ/view?usp=drive_link",
              },
              {
                img: "mdr_02",
                url: "https://drive.google.com/file/d/1xBIpBab_Kc86_pcUgd99PSWeqzXEj_th/view?usp=drive_link",
              },
              {
                img: "mdr_03",
                url: "https://drive.google.com/open?id=1fnNiT5TSEiOBc3GIvCnsaHv5zrzwEKHd&usp=drive_copy",
              },
              {
                img: "mdr_04",
                url: "https://drive.google.com/open?id=1rSeCOJ6RSyr-buQXowYEl-GicP5W47wN&usp=drive_copy",
              },
              {
                img: "mdr_05",
                url: "https://drive.google.com/open?id=1S3LBOvHR27f4Uf4cA5EbMWf9uGxoYe2F&usp=drive_copy",
              },
              {
                img: "mdr_06",
                url: "https://drive.google.com/open?id=16a032VWBQgVMljbcTixaFTAUZUadnops&usp=drive_copy",
              },
              {
                img: "mdr_07",
                url: "https://drive.google.com/open?id=1HnGj5nRhIr6F4UiJutMrKOpcjjgHndRs&usp=drive_copy",
              },
              {
                img: "mdr_08",
                url: "https://drive.google.com/open?id=14S1wuZNEwbTj-aFh9Teacf0i6ah0cvLf&usp=drive_copy",
              },
              {
                img: "mdr_09",
                url: "https://drive.google.com/open?id=1TchtcgAvzkVpAGz8rc0PD9QV8rHp-1Zw&usp=drive_copy",
              },
              {
                img: "mdr_10",
                url: "https://drive.google.com/open?id=1u522H7S92pVhRKAOePw9wKWfSFf04ncj&usp=drive_copy",
              },
              {
                img: "mdr_11",
                url: "https://drive.google.com/open?id=12tzyOM8jkqzPp5dyhbbDw66k9bgD6w5N&usp=drive_copy",
              },
              {
                img: "mdr_12",
                url: "https://drive.google.com/open?id=1e983rco9nW7iuLsuLiMCS2BRnrVsIWtu&usp=drive_copy",
              },
              {
                img: "mdr_13",
                url: "https://drive.google.com/open?id=16R1rlzqfuDRgEK3Svd2qdEw7-oFftZB7&usp=drive_copy",
              },
              {
                img: "mdr_14",
                url: "https://drive.google.com/open?id=1KBVt9qw5-5Y15OP9K20MdWYaQK4C65rE&usp=drive_copy",
              },
              {
                img: "mdr_15",
                url: "https://drive.google.com/open?id=1-2Nj578ab08EwsTDG_TRiz8zFiXjfD7V&usp=drive_copy",
              },
              {
                img: "mdr_16",
                url: "https://drive.google.com/open?id=11yVjtk1O7tvVq5xZ_ba3TYK9VKmdhqb1&usp=drive_copy",
              },
              {
                img: "mdr_17",
                url: "https://drive.google.com/open?id=1vNdWfNV1D9NMajj4lh53WEC7-szbiEkI&usp=drive_copy",
              },
              {
                img: "mdr_18",
                url: "https://drive.google.com/open?id=1OmTApnQ7YIfnyLHTYVZOcefJdy4TRzmP&usp=drive_copy",
              },
              {
                img: "mdr_19",
                url: "https://drive.google.com/open?id=1acR4JhfSA8eiwk3rfycNUC6t_KHZtQjV&usp=drive_copy",
              },
              {
                img: "mdr_20",
                url: "https://drive.google.com/open?id=16pH7HGDCneIqXstb7PVmkkEAEBfpHvhj&usp=drive_copy",
              },
              {
                img: "mdr_21",
                url: "https://drive.google.com/open?id=1yNp8Z1dhHTRZcVcseRCIs5Kn-LfOiLPr&usp=drive_copy",
              },
              {
                img: "mdr_22",
                url: "https://drive.google.com/open?id=1v2TN2danR53KDFcHi7GkSUtSRCrNWMao&usp=drive_copy",
              },
              {
                img: "mdr_23",
                url: "https://drive.google.com/open?id=1wEh4UaNzgfecapmNV4o2nMtyZnFqZAcP&usp=drive_copy",
              },
              {
                img: "mdr_24",
                url: "https://drive.google.com/open?id=14qaoB1PwfTXZbX84Ui1EpneB-Mqy_-JC&usp=drive_copy",
              },
              {
                img: "mdr_25",
                url: "https://drive.google.com/open?id=1MNOY1Wnjo-PWP9YAI6XdN7nTGPc0ZB_a&usp=drive_copy",
              },
              {
                img: "mdr_26",
                url: "https://drive.google.com/open?id=1yUoRN0C8zrTboY7yIWBJzfu8h6iYu3Zo&usp=drive_copy",
              },
              {
                img: "mdr_27",
                url: "https://drive.google.com/open?id=1O8G9j1Ncm5p027YfqweU1pwB4KGQagxe&usp=drive_copy",
              },
              {
                img: "mdr_28",
                url: "https://drive.google.com/open?id=137UkIgzay4ZT1uX9GqsqjyjGEr-8CtDf&usp=drive_copy",
              },
              {
                img: "mdr_29",
                url: "https://drive.google.com/open?id=11dAQ50TESpWuyDtoGRfDExnjDhogjFN0&usp=drive_copy",
              },
              {
                img: "mdr_30",
                url: "https://drive.google.com/open?id=1dA-2qw6kdFl88kl3VblB89KH1AECb0UU&usp=drive_copy",
              },
              {
                img: "mdr_31",
                url: "https://drive.google.com/open?id=1szRt_ivQTb0bnmvU2vwcIjkNUzvPNw6K&usp=drive_copy",
              },
              {
                img: "mdr_32",
                url: "https://drive.google.com/open?id=1xtRiNANNUhM3YCLZwao-2-Ja2Z0F_isv&usp=drive_copy",
              },
            ],
          },
        ],
      },
      {
        id: "graphic_material",
        title: "Graphic material",
        button: `Download our logos and graphic material`,
        description: `
          <p class="mb-5">Download our logos and graphic material</p>
        `,
        items_cols: 2,
        items: [
          {
            filename: "horizontal_pack",
            size: 200,
            title: "Horizontal version PACK",
            link: "https://drive.google.com/file/d/1VSeQIKsGNTbSCVHXvP8swXdjTdMCBaF0/view?usp=share_link",
          },
          {
            filename: "vertical_pack",
            size: 200,
            title: "Vertical version PACK",
            link: "https://drive.google.com/file/d/16fj3OkM6brAujrnXXgpH-oz7nXcEzIHT/view?usp=share_link",
          },
          {
            filename: "short_pack",
            size: 200,
            title: "Short version PACK",
            link: "https://drive.google.com/file/d/1PLIF52tJkJKWERwAiCUIqhsMmE_PrAKP/view?usp=share_link",
          },
        ],
      },
    ],
    selectedImageIndex: null,
    imagesArray: [],
    galleryTitle: null,
    galleryFolder: null,
    pageHeaderHeight: 900,
  }),
  methods: {
    openImageURL(url) {
      window.open(url, "_blank");
    },
    closeGallery() {
      this.galleryTitle = null;
      this.galleryFolder = null;
      this.imagesArray = [];
      this.selectedImageIndex = null;
    },
    nextImage() {
      if (this.selectedImageIndex + 1 >= this.imagesArray.length) {
        this.selectedImageIndex = 0;
      } else {
        this.selectedImageIndex += 1;
      }
    },
    prevImage() {
      if (this.selectedImageIndex === 0) {
        this.selectedImageIndex = this.imagesArray.length - 1;
      } else {
        this.selectedImageIndex -= 1;
      }
    },
    goToLink(link) {
      if (!link) return;

      let a = document.getElementById("dynLink");

      if (!a) {
        a = document.createElement("a");
        a.id = "dynLink";
        a.target = "_blank";
      }

      a.href = link;
      a.click();
    },
  },
};
</script>
